import { createRouter, createWebHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue'

const DefaultContainer = defineAsyncComponent(() => import("./layouts/DefaultContainer"));
const HomeScreen = defineAsyncComponent(() => import("./pages/HomeScreen"));
const SearchScreen = defineAsyncComponent(() => import("./pages/SearchScreen"));
const PackageDetail = defineAsyncComponent(() => import("./pages/PackageDetail"));
const SummaryBooking = defineAsyncComponent(() => import("./pages/SummaryBooking.vue"));
const AccommodationDetailV2 = defineAsyncComponent(() => import("./pages/AccommodationDetailV2"))
const MyBooking = defineAsyncComponent(() => import("./pages/MyBooking.vue"));
const PaymentResult = defineAsyncComponent(() => import("./pages/PaymentResult"));
const NotFound = defineAsyncComponent(() => import("./pages/NotFound"));
const CampaignDetail = defineAsyncComponent(() => import("./pages/CampaignDetail.vue"));

const routes = [
    {
        path: "/",
        component: DefaultContainer,
        children: [
            { path: '/', name: 'index', component: HomeScreen },
            { path: '/home', name: 'home', component: HomeScreen },
            { path: '/search', name: 'search', component: SearchScreen},
            { path: '/package-detail/:id', name: 'package-detail', component: PackageDetail },
            { path: '/summary-booking', name: 'summary-booking', component: SummaryBooking },
            { path: '/accommodation-detail/:id', name: 'accommodation-detail', component: AccommodationDetailV2 },
            { path: '/my-booking', name: 'my-booking', component: MyBooking },
            { path: '/payment-result', name: 'payment-result', component: PaymentResult },
            { path: "/:catchAll(.*)", component: NotFound },
            { path: '/campaign-detail/:id', name: 'campaign-detail', component: CampaignDetail },
        ]
    },
]

const router = createRouter({
    history: createWebHistory("/#/"),
    routes: routes,
});
// scrollBehavior() { window.scrollTo({ top: 0, behavior: 'instant' }); },
export default router;
