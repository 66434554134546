import { createApp } from 'vue';
import router from "./router";
import App from './App.vue';
import vant from 'vant';
import { createPinia } from 'pinia';
import VueGoogleMaps from '@fawmi/vue-google-maps'

//global css
import "@/assets/styles/globle.css";
import 'dayjs/locale/en';
import 'dayjs/locale/km';

//Font awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

//Translation package,
import { createI18n } from 'vue-i18n';
import en from "../src/assets/lang/en.json";
import km from "../src/assets/lang/km.json";
const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    silentTranslationWarn: true, //Need to set TRUE when deploy to production server
    messages: {
        en: en,
        km: km,
    },
    legacy: false
});

const pinia = createPinia();

//Vue bridge to work with ABA native app
import VueBridgeGateway from "vue-bridge-gateway";
import VConsole from 'vconsole';
let vconsole;

if(process.env.VUE_APP_ENV == 'local') vconsole = new VConsole({ theme: 'dark' });
// vconsole = new VConsole({ theme: 'dark' });

const app = createApp(App)
app.component('fa-icon', FontAwesomeIcon);
app.use(vant);
app.use(i18n);
app.use(router);
app.use(pinia);

if(vconsole) app.use(vconsole);

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDN0sNwcmaWJgRZwh0t8g2WFmVazAv78MY',
        libraries: 'places',
    }
});

app.use(VueBridgeGateway, { debug: true, delay: 200 });

//Should add to the last line
app.mount('#app');