<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { Locale } from 'vant';
import en from 'vant/es/locale/lang/en-US';

const locale = reactive({});

onMounted(() => {
    locale.key = 'en-US';
    locale.value = en;
    Locale.use(locale.key, locale.value);
})

</script>
